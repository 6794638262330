/* General Styles */
/* body {
    font-family: Arial, sans-serif;
} */

.blog-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.blog-post {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.blog-meta {
    color: #555;
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.blog-content {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.separator {
    margin: 20px 0;
}

.comment-section {
    margin-top: 40px;
}

.comment-section-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.comments-list {
    margin-bottom: 20px;
}

.comment {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.comment-content p {
    margin: 0;
    font-size: 1rem;
}

.comment-meta {
    color: #888;
    font-size: 0.8rem;
    margin-top: 10px;
}

.comment-form {
    margin-top: 20px;
}

.comment-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    resize: vertical;
}

.comment-submit-btn {
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.comment-submit-btn:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .blog-title {
        font-size: 2rem;
    }

    .blog-content {
        font-size: 1rem;
    }

    .comment-section-title {
        font-size: 1.5rem;
    }
}
